import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: localStorage.getItem('userInfo')
      ? JSON.parse(localStorage.getItem('userInfo'))
      : {},
    token: localStorage.getItem('token') || '',
    templateList: []
  },
  getters: {},
  mutations: {
    setTemplateList (state, list) {
      state.templateList = list
    },
    getUserInfo (state, userInfo) {
      state.userInfo = userInfo
      localStorage.setItem('userInfo', JSON.stringify(userInfo))
    },
    setToken (state, token) {
      state.token = token
      localStorage.setItem('token', token)
    },
    setPasswordType (state) {
      state.userInfo.is_password = true
      localStorage.setItem('userInfo', JSON.stringify(state.userInfo))
    },
    logOut (state) {
      state.token = ''
      state.userInfo = {}
      localStorage.removeItem('token')
      localStorage.removeItem('userInfo')
    }
  },
  actions: {},
  modules: {}
})
