import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/home/index.vue')
  },
  {
    path: '/move',
    name: 'Move',
    component: () => import('../views/move/index.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/about/index.vue')
  },
  {
    path: '/chat',
    name: 'Chat',
    component: () => import('../views/chat/index.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/index.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.query.token) {
    store.commit('setToken', to.query.token)
    store.commit('getUserInfo', {
      avatar: '',
      name: '临时用户',
      quota: -1,
      user_id: -1
    })
  }
  const token = localStorage.getItem('token')
  if (to.path === '/login' && token) {
    // if (window.innerWidth <= 900) {
    //   next('/move')
    //   return
    // }
    next('/')
    return
  }
  if (to.path !== '/login' && !token && to.path !== '/home') {
    next('/login')
    return
  }
  next()
})
export default router
